// /*==========================================================
// Social Media Section - Slider
// ==========================================================*/

function smFeedSliderInit() {

	//Twitter
	$('.twitterFeed').flickity({
		cellAlign: 'left',
		contain: true,
		pageDots: false,
		prevNextButtons: false,
		imagesLoaded: true,
		on: {
			ready: function () {
				// Add custom flickity previous and next button
				var customButton = '<div class="flickity-buttons"><button class="flickity-prev-next-button previous" type="button"aria-label="Previous"></button><button class="flickity-prev-next-button next" type="button"aria-label="Next"></button></div>';
				$(customButton).insertBefore('#smTwitter .viewMore .viewAll');
			}
		}
	});

	$('#smTwitter .flickity-prev-next-button.previous').on('click', function () {
		$('.twitterFeed').flickity('previous');
	});

	$('#smTwitter .flickity-prev-next-button.next').on('click', function () {
		$('.twitterFeed').flickity('next');
	});

	// Youtube
	//$('.youtubeFeed').flickity({
	//	cellAlign: 'left',
	//	contain: true,
	//	pageDots: false,
	//	prevNextButtons: false,
	//	imagesLoaded: true,
	//	on: {
	//		ready: function () {
	//			// Add custom flickity previous and next button
	//			var customButton = '<div class="flickity-buttons"><button class="flickity-prev-next-button previous" type="button"aria-label="Previous"></button><button class="flickity-prev-next-button next" type="button"aria-label="Next"></button></div>';
	//			$(customButton).insertBefore('#smVideos .viewMore .viewAll');
	//		}
	//	}
	//});

	//$('#smVideos .flickity-prev-next-button.previous').on('click', function () {
	//	$('.youtubeFeed').flickity('previous');
	//});

	//$('#smVideos .flickity-prev-next-button.next').on('click', function () {
	//	$('.youtubeFeed').flickity('next');
	//});
}