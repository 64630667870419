/*======================================================
  responsiveScripts.js
  ======================================================*/

var mobile = false;
var dropDownContainerVisible = false;
var navOpened = false;

/*==========================================================
  SET JAVASCRIPT BREAKPOINTS AND ENTER/EXIT SCRIPTS
==========================================================*/

function jsMediaQueries() {
	if (Modernizr.mq('only screen and (min-width: 992px)')) {
		$('html').removeClass('mobile').removeClass('tablet').addClass('desktop');
	}

	if (Modernizr.mq('only screen and (max-width: 991px)') && (Modernizr.touch) || Modernizr.mq('only all and (max-width: 991px)')) {

		$('html').removeClass('mobile').removeClass('desktop').addClass('tablet');
		mobile = false;
	}
	if (Modernizr.mq('only screen and (max-width: 767px)')) {
		$('html').removeClass('tablet').removeClass('desktop').addClass('mobile');
		document.addEventListener("touchstart", function () { }, true) // ADD 'ACTIVE' STATE TO TOUCH LINKS
		mobile = true;
	}
	if (Modernizr.mq('only screen and (max-width: 991px)')) {
		$("#nav").superfish('destroy');
		// $("#mainNav #nav li a, #mainNav #nav li").on("click", function(){
		// 	event.preventDefault();
		// 	$('#mainNav #nav li').removeClass('sfHover');
		// 	$('#mainNav #nav li').find('dropDownContainer').stop();
		// 	$('#mainNav #nav li').find('dropDownContainer').hide();
		// });
	} else {
		$("#mainNav #nav li a").removeAttr('role aria-controls aria-expanded aria-haspopup');

		$('#nav > li').removeClass('mobile-open');
		$("#nav").superfish({
			cssArrows: false,
			speed: "normal",
			speedOut: "fast"
		});
	}
}

function mainNavMobile() {
	// ADD EXPANDER FOR MAIN NAV
	var mobileNavButton = '<div class="mainNavItem_expander" tabindex="0" role="button" aria-expanded="false" aria-label="Toggle Section"></div>';
	$(mobileNavButton).insertBefore('#mainNav > ul > li > .dropDownContainer')
	$('.mainNavItem_expander').on('click', function (event) {
		event.preventDefault()
		event.stopPropagation()

		if ($(this).parent().hasClass('mobile-open')) {
			$(this).parent().removeClass("mobile-open");
			$(this).attr("aria-expanded", "false");
			$(this).next().slideToggle(240);
		} else {
			$('.mainNavItem_expander').parent().removeClass("mobile-open");

			$(this).parent().toggleClass('mobile-open');
			$(this).next().slideToggle(240);
			$(this).attr("aria-expanded", "true");
			$('.mainNavItem_expander').parent().each(function (index) {
				if (!$(this).hasClass('mobile-open')) {
					$(this).find('.dropDownContainer').slideUp(240);
				}
			});
		}
	})
	$('.mainNavItem_expander').on('keyup', function (event) {
		event.preventDefault()
		event.stopPropagation()
		var code = event.keyCode || event.which;
		if (code === 13) { //Enter keycode
			if ($(this).parent().hasClass('mobile-open')) {
				$(this).parent().removeClass("mobile-open");
				$(this).attr("aria-expanded", "false");
				$(this).next().slideToggle(240);
			} else {
				$('.mainNavItem_expander').parent().removeClass("mobile-open");

				$(this).parent().toggleClass('mobile-open');
				$(this).next().slideToggle(240);
				$(this).attr("aria-expanded", "true");
				$('.mainNavItem_expander').parent().each(function (index) {
					if (!$(this).hasClass('mobile-open')) {
						$(this).find('.dropDownContainer').slideUp(240);
					}
				});
			}
		}

	})
}

function mainNavHeight() {
	$('#nav > li a.mainNavItem').css('height', '');

	if (Modernizr.mq('only screen and (min-width: 991px)')) {
		var navHeight = $('#nav').outerHeight();
		$('#nav > li a.mainNavItem').css('height', navHeight);
	} else {
		$('#nav > li a.mainNavItem').css('height', '');
	}
}

$(function () {
	//Set Uber classes to target iCreate modes
	//public mode
	// if (!iCreateObject.isSiteInICreateMode && !iCreateObject.isSiteInPreviewMode){ 
	//     $('#uber').addClass('public-mode');
	// }

	//public mode or preview mode
	// if (!iCreateObject.isSiteInICreateMode || iCreateObject.isSiteInPreviewMode){ 
	//     $('#uber').addClass('public-preview-mode');
	// }

	//public mode or edit mode
	// if (!iCreateObject.isSiteInICreateMode || !iCreateObject.isSiteInPreviewMode){ 
	//     $('#uber').addClass('public-edit-mode');
	// }

	//preview mode
	// if (iCreateObject.isSiteInPreviewMode){ 
	//     $('#uber').addClass('preview-mode');
	// }

	//preview mode or edit mode
	if (iCreateObject.isSiteInICreateMode) {
		$('#uber').addClass('preview-edit-mode');
	}

	//edit mode
	if (iCreateObject.isSiteInICreateMode && !iCreateObject.isSiteInPreviewMode) {
		$('#uber').addClass('edit-mode');
	}

	// Fix Gallery not visible in Accordion on expand
	$('.AccordionTrigger').on('click', function () {
		if (typeof (Event) === 'function') {
			// modern browsers
			window.dispatchEvent(new Event('resize'));

		} else {
			//This will be executed on old browsers and especially IE
			var resizeEvent = window.document.createEvent('UIEvents');
			resizeEvent.initUIEvent('resize', true, false, window, 0);
			window.dispatchEvent(resizeEvent);
		}
	});

	/*==========================================================
		IMAGE POLYFILLS
	==========================================================*/

	$(window).bind("load resize", function () {
		if ($.isFunction($.fn.imgPolyfill)) {
			$(".imageBoxHover-image").imgPolyfill();
			$("#newsBackground img").imgPolyfill();
		}
	});

	/*==========================================================
		  MOBILE NAV TRIGGER
	==========================================================*/

	// Mobile nav open/close
	$("#mobileTrigger").click(function (event) {
		event.preventDefault();
		$(this).toggleClass("is-active");
		$("#mainNav").slideToggle("fast");
	});


	/*==========================================================
		DETACH SCRIPTS
	==========================================================*/

	var isMobile = false;

	function moveElements() {
		if (Modernizr.mq("only screen and (max-width: 991px)")) {
			if (!isMobile) {
				// Mobile
				if ($(".searchContainer").is("*")) {
					$(".searchContainer").detach().prependTo("#mainNav");
				} // search container
				if ($("#topNav").is("*")) {
					$("#topNav").detach().appendTo("#mainNav");
				} // top nav links
				if ($("#topNavContainer #translateContainer").is("*")) {
					$("#translateContainer").detach().appendTo("#mainNav");
				} // Language Toggle

				if ($("#uber").hasClass("scrolled")) {
					jQuery('#uber').removeClass('scrolled');
					$('#topNav-bottom').detach().prependTo('#topNavContainer .ic-container-fluid');
					$('#mainNav').detach().appendTo('#bottomNavContainer');
				}

				if ($('#subNavWrapper #contactMainContainer').is('*')) {
					$('#contactMainContainer').detach().appendTo('#contentInt');
				} // Contact
				if ($('#subNavWrapper #pageSubscription').is('*')) {
					$('#pageSubscription').detach().appendTo('#contentInt');
				} // Page Subscription
			}
			isMobile = true;
		} else {
			if (isMobile) {
				// Desktop
				if ($(".searchContainer").is("*")) {
					$(".searchContainer").detach().appendTo("#headerRightInner");
				} // search container
				if ($("#mainNav #translateContainer").is("*")) {
					$("#translateContainer").detach().prependTo("#topNavContainer");
				} // top nav links
				if ($("#topNav").is("*")) {
					$("#topNav").detach().prependTo("#topNavContainer");
				} // Language Toggle

				if ($('#contentInt > #contactMainContainer').is('*')) {
					$('#contactMainContainer').detach().appendTo('#subNavWrapper');
				} // Contact
				if ($('#contentInt > #pageSubscription').is('*')) {
					$('#pageSubscription').detach().appendTo('#subNavWrapper');
				} // Page Subscription
				$('.searchMobile').remove();
				
			}
			isMobile = false;
		}
	}

	if ($('#uber').hasClass('interior')) {

		$('.toggle, .extendedToggle').attr('tabindex', '0').attr('aria-expanded', 'false');
		$('li.open > .toggle').attr('aria-expanded', 'true');
		$('.extendedToggle').attr('tabindex', '0');
		$('.subNavTrigger').attr('aria-expanded', 'false');

		//layout builder match height and unwrap lb-imageBox with no link
		if ($().matchHeight) {
			$('.lb-imageBox_header').matchHeight();
		}
		$('.fbg-row.lb-imageBox a').each(function () {
			if ($(this).attr('href') == "" || $(this).attr('href') == "#" || !$(this).attr('href')) { //don't style link
				$(this).contents().unwrap("a");
			}
		});
		if ($("#CalendarTemplate").length > 0) {
			$('#uber').addClass('calendarView');
		}
	}

	$(window).resize(function () {
		$('#uber').css('padding-top', 0);
		jsMediaQueries();
		moveElements();
		mainNavHeight();
	});

	$(window).load(function () {
		$('body').addClass('loaded');
		$('#uber').css('padding-top', 0);
		mainNavMobile();
		jsMediaQueries();
		moveElements();
		mainNavHeight();
	});


	/*==========================================================
		SUB NAV
	==========================================================*/
	$(function () {

		// Hide Mobile subNav trigger label if subnav is empty
		if (!$('#subNavWrapper #subNavContainer .subNav li').first().children().length) {
			$('.triggerLabel').addClass('hide');
		} else {
			$('.triggerLabel').removeClass('hide');
		}
	});

	/*==========================================================
	  ADD MOBILE DEVICE CLASSES
	==========================================================*/

	if (navigator.userAgent.match(/(iPod|iPhone|iPad)/i)) {
		$('html').addClass('x-apple');
	}
	if (navigator.userAgent.match(/(Android)/i)) {
		$('html').addClass('x-android');
	}
	if (navigator.userAgent.match(/(blackberry)/i)) {
		$('html').addClass('x-blackberry');
	}
	if (navigator.userAgent.match(/(bb10)/i)) {
		$('html').addClass('x-bb10');
	}

	/*==========================================================
	NAVIGATION SCRIPTS
	==========================================================*/
	// Options
	let mainNavTrigger = 'click', // 'click' or 'hover'
		dropdownToggleClass = 'sfHover'; // same class as superfish for CSS consistency between different trigger types

	// Elements
	let $nav = $('#nav'),
		$navItem = $nav.find('> li'),
		$navLink = $navItem.find('> a');

	$(function () {
		$("#mainNav #nav li a").removeAttr('role aria-controls aria-expanded aria-haspopup');
		mainNavClick();
		navClickDropdown(false);
	});

	function mainNavClick() {
		$nav.removeClass('nav--click');

		$.fn.slideToggleBool = function (bool, options) {
			return bool ? $(this).slideDown(options) : $(this).slideUp(options);
		}

		function navCloseDropdown() {
			$navItem.find('.dropDownContainer').slideToggleBool(false, 240).parent('li').removeClass(dropdownToggleClass).find('a, .mainNavItem_expander').attr('aria-expanded', false);
			$("#mainNav ul li").removeAttr("tabindex");
		}

		function navClickDropdown(bindClick) {
			$navItem.each(function (index) {
				let $dropdown = $(this).find('.dropDownContainer');

				if ($dropdown.length) {
					let dropdownID = $dropdown.attr('id') ? $dropdown.attr('id') : 'navItemDropdown' + index,
						$dropdownToggleEls = $navItem.eq([index]).find('a');

					$dropdown.removeAttr('id');
					$dropdownToggleEls.removeAttr('role aria-controls aria-expanded aria-haspopup');

					$navItem.eq([index]).find('a').removeAttr('role');

					if (bindClick) {
						$dropdownToggleEls.click(function (event) {
							if ($(this).attr('role') || $(this).is('button')) {
								event.preventDefault();
								event.stopPropagation();
							}

							let wasOpen = $(this).parent('li').find('.dropDownContainer').is(':visible');

							// Close all dropdowns
							$navItem.find('.dropDownContainer').slideToggleBool(false, 240).parent('li').removeClass('sfHover').find('[aria-expanded="true"]').attr('aria-expanded', false);

							// Open clicked dropdown if it wasn't just closed
							if (!wasOpen) {
								$(this).parent('li').removeClass('sfHover');
								$dropdown.slideToggleBool(false, 240);
								$("#mainNav ul li").removeAttr("tabindex");
								$dropdownToggleEls.removeAttr('role aria-controls aria-expanded aria-haspopup');
							} else {
								$(this).parent('li').removeClass('sfHover');
								$("#mainNav ul li").removeAttr("tabindex");
								$dropdownToggleEls.removeAttr('role aria-controls aria-expanded aria-haspopup');
							}
						});
					} else {
						$('#mainNav li a').on('click', function () {
							$navItem.find('.dropDownContainer').slideToggleBool(false, 0).parent('li').removeClass('sfHover').find('[aria-expanded="true"]').attr('aria-expanded', false);
						});
					}
				}
			});

			if (window.matchMedia('(max-width: 991px)').matches) {
				// Only toggle dropdown with .mainNavItem_expander on mobile
				navClickDropdown(false);
				$navItem.find('a').removeAttr('role aria-controls aria-expanded aria-haspopup');
			}
		}

		navClickDropdown(false);

		//debounce resize instead of standard resize
		$(window).debounceResize(function () {
			navCloseDropdown();
			navClickDropdown();
		});

		// Close dropdown if clicked outside of it
		$(document).on('click', function (event) {
			if (!$(event.target).closest('.dropDownContainer:visible').length) {
				navCloseDropdown();
			}
		});
	}
	function mainNavHover() {
		$.getScript('https://js.esolutionsgroup.ca/js/libs/hover-intent/1.8.0/jquery.hoverIntent.min.js', function () {
			$.getScript('https://js.esolutionsgroup.ca/js/libs/jquery_superfish/1.7.4/superfish.min.js', function () {
				//   $nav.addClass('nav--hover');
				// $nav.addClass('sfHover');
				mainNavHoverFn();
			});
		});
	}

	function mainNavHoverFn() {
		if (window.matchMedia('(max-width: 991px)').matches) {
			$nav.superfish('destroy');
		} else {
			$navItem.removeClass('mobile-open');
			$nav.superfish({
				cssArrows: false,
				speed: 'normal',
				speedOut: 'fast'
			});
		}

		//debounce resize instead of standard resize
		$(window).debounceResize(function () {
			mainNavHoverFn();
		});
	}
	/*==========================================================
	  SCROLL FUNCTIONS
	==========================================================*/

	var headerHeight = $("#bottomNavContainer").height() + 100;

	// Sticky Main Nav
	$(window).scroll(function () {

		if (Modernizr.mq('only screen and (min-width: 992px)')) {
			if (jQuery(this).scrollTop() > ($('header').outerHeight())) {
				$("#stickyHeader #returnHome a").attr("tabindex", 0);
			} else {
				$("#stickyHeader #returnHome a").attr("tabindex", -1);
			}

			if (jQuery(this).scrollTop() > ($('header').outerHeight())) {
				jQuery('#uber').addClass('scrolled');
				$('#topNav-bottom').detach().prependTo('#stickyHeaderRight');
				$('#mainNav').detach().appendTo('#stickyHeaderRight');
			} else {
				jQuery('#uber').removeClass('scrolled');
				$('#topNav-bottom').detach().prependTo('#topNavContainer .ic-container-fluid');
				$('#mainNav').detach().appendTo('#bottomNavContainer');
			}
		}

	});

	// Scroll to Anchor links when link to anchor is clicked on the same page
	$('#printAreaContent a[href*=#]:not([href=#]), #printAreaContentDotNet a[href*=#]:not([href=#]), #skipContentLink').click(function () {
		if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
			var target = $(this.hash)
			target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
			if (target.parents('.AccordionContent').length || target.parents('.AccordionTrigger').length) { //if clicking accordion link or link in accordion

				if (target.parents('.AccordionTrigger').length) {
					target.closest('.AccordionTrigger').addClass('open');
					target.closest('.AccordionTrigger').next().slideToggle("100");
					target.focus();
				} else if (target.parents('.AccordionContent').length) {
					$('.AccordionContent:visible').slideToggle("100");
					$('.AccordionTrigger').removeClass('open');
					target.parents('.AccordionContent').slideDown("100");
					target.parents('.AccordionContent').prev().addClass('open');
					if (target.length) {
						$('html,body').animate({
							scrollTop: target.offset().top - headerHeight //Offset scroll position of anchor link for sticky header
						}, 1000);
						target.focus();
						return false;
					}
				}
			} else if (target.length) {
				$('html,body').animate({
					scrollTop: target.offset().top - headerHeight //Offset scroll position of anchor link for sticky header
				}, 1000);
				target.focus();
				return false;
			}
		}
	});

	$(window).load(function () {

		// Ignore if page is using ScrollTo functionality from URL (facility module)
		// OR - if emergency carousel exists        
		var pageURL = window.location.href;
		if (pageURL.indexOf("ScrollTo=") == -1 && !$("#emergencyCarousel").is("*")) {

			//Scroll to Anchor links on page load when anchor hash is part of URL
			if (window.location.hash) {
				var hash = window.location.hash.substring(1),
					hashIsEl = true;
				if (hash != '') {
					if ($('[name=' + hash + ']').length > 0) {
						hash = $('[name=' + hash + ']');
					} else if ($('#' + hash).length > 0) {
						hash = $('#' + hash);
					} else {
						hashIsEl = false;
					}
					if (hashIsEl) {
						var offsetTop = hash.offset().top - 0;
						$('html, body').animate({
							scrollTop: offsetTop
						}, 1000);
						if ($(hash).parents('.AccordionTrigger').length) {
							$(hash).parents('.AccordionTrigger').trigger("click");
						}
						$(hash).focus();
					}
				}
			} else if ($('#intBg').length && !$('.hideBanner').length && !$('.blogFeed').length) {
				// Scroll to #pageHeading and by pass interior banner if loading a page from the same/current vertical
				var offsetTop = $('#pageHeading').offset().top - 0;

				if ($(this).scrollTop() < offsetTop) { // if scroll is less than the scroll to position.
					var loc = window.location.pathname;
					var referrer = document.referrer.replace(/^.*\/\/[^\/]+/, '');

					var currentDir = loc.substring(0, loc.lastIndexOf('/'));
					var referrerDir = referrer.substring(0, referrer.lastIndexOf('/'));


					if ($('#uber').hasClass('interior')) {
						if (referrerDir.toLowerCase() == currentDir.toLowerCase()) {
							$('html, body').animate({
								scrollTop: offsetTop
							}, 1000);
						}
					}
				}
			}

		}
	});

	// Set Tab focus to appear below sticky header
	$("#uber").on("keyup", "select, input, textarea, button, a, div[tabindex='0']", function (e) {
		var code = (e.keyCode ? e.keyCode : e.which);
		if (code == 9 && $("select:focus, input:focus, textarea:focus, button:focus, a:focus, div[tabindex='0']:focus").length) {
			if (e.target.id == "skipContentLink") { //scroll up to skipContentLink
				$("html, body").stop().animate({
					scrollTop: 0
				}, 500);
			} else if (!$(e.currentTarget).closest("header").length) {
				var ele = $(e.currentTarget).offset().top;
				var eleTop = ele - $(window).scrollTop();
				var newPos = ele - headerHeight - 25;

				if (headerHeight > eleTop) {
					$("html, body").stop().animate({
						scrollTop: newPos
					}, 500);
				}
			}
		}
	});

	/*==========================================================
	  RESPONSIVE IFRAME
	==========================================================*/

	if (!iCreateObject.isSiteInICreateMode || iCreateObject.isSiteInPreviewMode) {
		if ($('iframe').length > 0) {
			$('iframe').each(function (index) {
				var patternVideo = /(youtube\.com|youtu\.be|vimeo\.com)/,
					video = patternVideo.test($(this).attr('src'));
				var patternIssuu = /(issuu\.com)/,
					issuu = patternIssuu.test($(this).attr('src'));
				if (video) {
					var iframeWidth = $(this).outerWidth(),
						iframeHeight = $(this).outerHeight(),
						iframePadding = iframeHeight / iframeWidth * 100;
					if ($(this).parent('.iframe-container').length == 0) {
						$(this).wrap('<div class="iframe-container" />');
					}

					$(this).parent('.iframe-container').css('padding-bottom', iframePadding + '%');
					if ($(this).attr('width')) {
						if (!$(this).attr('width').match('%$')) {
							$(this).parent('.iframe-container').wrap('<div class="iframe-container-wrapper" style="max-width:' + $(this).attr('width') + 'px"></div>');
						}
					}
				} else if (issuu) {
					var iframeWidth = $(this).outerWidth(),
						iframeHeight = $(this).outerHeight(),
						iframePadding = iframeHeight / iframeWidth * 100;
					$(this).parent('.issuuembed').addClass('iframe-container').css('padding-bottom', iframePadding + '%');
				}
			});
		}
	}
});

/*==========================================================
	  7. BROWSER DETECTION SCRIPT
==========================================================*/

(function (factory) {
	if (typeof define === 'function' && define.amd) {
		define(['jquery'], function ($) {
			return factory($);
		});
	} else if (typeof module === 'object' && typeof module.exports === 'object') {
		module.exports = factory(require('jquery'));
	} else {
		factory(window.jQuery);
	}
}(function (jQuery) {
	"use strict";

	function uaMatch(ua) {
		if (ua === undefined) {
			ua = window.navigator.userAgent;
		}
		ua = ua.toLowerCase();

		var match = /(edge)\/([\w.]+)/.exec(ua) || /(opr)[\/]([\w.]+)/.exec(ua) || /(chrome)[ \/]([\w.]+)/.exec(ua) || /(iemobile)[\/]([\w.]+)/.exec(ua) || /(webkit)[ \/]([\w.]+).*(version)[ \/]([\w.]+).*(safari)[ \/]([\w.]+)/.exec(ua) || /(opera)(?:.*version|)[ \/]([\w.]+)/.exec(ua) || /(msie) ([\w.]+)/.exec(ua) || ua.indexOf("trident") >= 0 && /(rv)(?::| )([\w.]+)/.exec(ua) || ua.indexOf("compatible") < 0 && /(mozilla)(?:.*? rv:([\w.]+)|)/.exec(ua) || [];

		var platform_match = /(ipad)/.exec(ua) || /(ipod)/.exec(ua) || /(windows phone)/.exec(ua) || /(iphone)/.exec(ua) || /(kindle)/.exec(ua) || /(silk)/.exec(ua) || /(android)/.exec(ua) || /(win)/.exec(ua) || /(mac)/.exec(ua) || /(linux)/.exec(ua) || /(cros)/.exec(ua) || /(playbook)/.exec(ua) || /(bb)/.exec(ua) || /(blackberry)/.exec(ua) || [];

		var browser = {},
			matched = {
				browser: match[5] || match[3] || match[1] || "",
				version: match[2] || match[4] || "0",
				versionNumber: match[4] || match[2] || "0",
				platform: platform_match[0] || ""
			};

		if (matched.browser) {
			browser[matched.browser] = true;
			browser.version = matched.version;
			browser.versionNumber = parseInt(matched.versionNumber, 10);
		}

		if (matched.platform) {
			browser[matched.platform] = true;
		}

		if (browser.android || browser.bb || browser.blackberry || browser.ipad || browser.iphone || browser.ipod || browser.kindle || browser.playbook || browser.silk || browser["windows phone"]) {
			browser.mobile = true;
		}

		if (browser.cros || browser.mac || browser.linux || browser.win) {
			browser.desktop = true;
		}

		if (browser.chrome || browser.opr || browser.safari) {
			browser.webkit = true;
		}

		if (browser.rv || browser.iemobile) {
			var ie = "msie";
			matched.browser = ie;
			browser[ie] = true;
		}

		if (browser.edge) {
			delete browser.edge;
			var msedge = "msedge";
			matched.browser = msedge;
			browser[msedge] = true;
		}

		if (browser.safari && browser.blackberry) {
			var blackberry = "blackberry";
			matched.browser = blackberry;
			browser[blackberry] = true;
		}

		if (browser.safari && browser.playbook) {
			var playbook = "playbook";
			matched.browser = playbook;
			browser[playbook] = true;
		}

		if (browser.bb) {
			var bb = "blackberry";
			matched.browser = bb;
			browser[bb] = true;
		}

		if (browser.opr) {
			var opera = "opera";
			matched.browser = opera;
			browser[opera] = true;
		}

		if (browser.safari && browser.android) {
			var android = "android";
			matched.browser = android;
			browser[android] = true;
		}

		if (browser.safari && browser.kindle) {
			var kindle = "kindle";
			matched.browser = kindle;
			browser[kindle] = true;
		}

		if (browser.safari && browser.silk) {
			var silk = "silk";
			matched.browser = silk;
			browser[silk] = true;
		}

		browser.name = matched.browser;
		browser.platform = matched.platform;
		return browser;
	}

	window.jQBrowser = uaMatch(window.navigator.userAgent);
	window.jQBrowser.uaMatch = uaMatch;

	if (jQuery) {
		jQuery.browser = window.jQBrowser;
	}

	return window.jQBrowser;
}));


$(function () {
	if ($.browser.name == 'msie') {
		$('html').addClass('ie');
	}
	if ($.browser.name == 'msedge') {
		$('html').addClass('msedge');
	}
	if ($.browser.name == 'opera') {
		$('html').addClass('opera');
	}
	if ($.browser.name == 'mozilla') {
		$('html').addClass('moz');
	}
	if ($.browser.name == 'safari') {
		$('html').addClass('safari');
	}
	if ($.browser.name == 'chrome') {
		$('html').addClass('chrome');
	}
});

$(document).on('ready', function () {
	var observer = new MutationObserver(function (mutations) {
		mutations.forEach(function (mutation) {
			// get the added nodes in the DOM
			var nodes = Array.prototype.slice.call(mutation.addedNodes);

			nodes.forEach(function (node) {
				// select the option element has "Select Language"
				if ($(node).is('input.gsc-input')) {

					// change the text of the option to "Translate"
					if (window.location.href.includes('/en/')) {
						$(node).attr('placeholder', 'What are you looking for?');
					} else if (window.location.href.includes('/fr/')) {
						$(node).attr('placeholder', 'Recherche');
					} else {
						$(node).attr('placeholder', 'What are you looking for?');
					}
				}
			});
		});
	});

	// listen to the node changes within #google_translate_element element
	observer.observe(document.querySelector(".searchContainer"), {
		childList: true,
		subtree: true,
		attributes: false,
		characterData: false,
	});
});


$(function () {
	if (!iCreateObject.isSiteInICreateMode) {
		$('#ShareItemsPlaceholder li').last().addClass('last');
	} else {
		$('#ShareItemsPlaceholder').empty();
	}

	if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
		$('#Share a.ShareLink').click(function (e) {
			if (isSiteinICreateMode()) {
				showNAMessage();
			} else {
				e.preventDefault();
				$('#Share').trigger('mouseenter');
			}
		});
	}

	//override the share links to current URL
	var curpage = encodeURIComponent(window.location);
	var curPageHeading = $(document).find('h1').length ? $.trim($('h1').text()).replace(/\s\s+/g, ' ') : $.trim($(document).find('title').text());
	$('#fbShare').attr('href', 'https://www.facebook.com/sharer.php?u=' + curpage);
	$('#liShare').attr('href', 'https://www.linkedin.com/shareArticle?mini=true&url=' + curpage);
	$('#twShare').attr('href', 'https://www.twitter.com/intent/tweet?text=Check+out+ ' + curPageHeading + ' ' + curpage);

});

/*==========================================================
	TEXT SIZE
==========================================================*/

$(function () {
	var fontSizeInitial = parseInt($('#printArea').css('font-size')),
		fontSize = fontSizeInitial;

	$('.resizeText').find('button').on('click', function (event) {
		event.preventDefault();
		if (iCreateObject.isSiteInICreateMode) {
			showNAMessage();
		} else {
			var sizer = $(this);
			if (sizer.hasClass('textDefault')) {
				fontSize = fontSizeInitial;
				resizeText(fontSize);
			} else if (sizer.hasClass('textDecrease') && fontSize > 8) {
				fontSize--;
				resizeText(fontSize);
			} else if (sizer.hasClass('textIncrease') && fontSize < 24) {
				fontSize++;
				resizeText(fontSize);
			}
		}
	});
});

function resizeText(size) {
	$('#printArea').css('font-size', size);
	$('#printArea .datatable .titlerow').css('font-size', size);
	$('.Accordion .AccordionTrigger *').css('font-size', size);
	$('.Accordion .AccordionContent').css('font-size', size);
	$('.Accordion .AccordionTitle').css('font-size', size);
	$('.icHowto').css('font-size', size);
	if ($('#uber').hasClass('interior')) {
		$.fn.matchHeight._update();
	}
}