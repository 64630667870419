/*======================================================
	SLIDERINIT.JS - Homepage Slider
====================================================== */

if ($('#uber').is('.homepage')) {



	if (typeof bindEvent == 'function') { } else {
		function bindEvent(el, eventName, eventHandler) {
			if (el.addEventListener) {
				el.addEventListener(eventName, eventHandler, false);
			} else if (el.attachEvent) {
				el.attachEvent('on' + eventName, eventHandler);
			}
		}
	}

	if (!jQuery("script[src='https://js.esolutionsgroup.ca/js/libs/ios_slider/structure/1.3.43/jquery.iosslider.min.js']").length) {
		var script = document.createElement('script');
		script.setAttribute('src', 'https://js.esolutionsgroup.ca/js/libs/ios_slider/structure/1.3.43/jquery.iosslider.min.js');
		script.setAttribute('type', 'text/javascript');
		jQuery('body').append(script);
	}

	function decodeEntities(encodedString) {
		var textArea = document.createElement('textarea');
		textArea.innerHTML = encodedString;
		return textArea.value;
	}

	function initSlider() {
		if ($.fn.rotating_banners) {
			$("#rotatingBannerContainer").rotating_banners("IOSSlider", {
				bannerSizeText: "",
				skin: "basic",
				skinVersion: "3.2.0",
				wrapperHTMLStructure: "<div class=\"ic-slider\"><div id=\"rotatingBanners\" class=\"bannerContainer slider rsDefault\"></div></div><div class=\"ic-slider-controls\"><ul class=\"ic-slider-dot-pagination\"></ul><a class=\"pause\" href=\"#\" title=\"Play/Pause Banner\">Play/Pause Banner</a></div>",
				slideHTMLStructure: "<div class=\"slide\"><div class=\"bannerImage\" title=\"{title}\" data-image-title=\"{title}\" data-image-alt=\"{altText}\" onclick=\"{onclickEvent}\" style=\"background-image: url({imageURL});\"></div><div class=\"ic-slider-content ic-slider-text\"><div class=\"ic-slider-content-foreground\">{bannerText}</div></div></div>",
				textAreas: [{
					id: "bannerText",
					name: "Banner Text"
				}],
				maxItems: 5,
				fileManagerUrls: [
					"/230002_FileManager/Plugins/FileManager/FileManagerPlugin.js"
				],
				useEsolFileManager: true,
				imageResizerMode: "fixed",
				imageResizerWidth: "522px",
				imageResizerHeight: "430px",
				customFields: [],
				enableCustomStyles: true,
				customStyles: [],
				//remove link editor button from banner text
				editorSettings: $.extend(iCreateObject.isSiteInICreateMode ? $.fn.tinyMCE_iCreate_defaults.standardBannerTextSettings : {},
					{
						theme_advanced_blockformats: "h2,p",
						theme_advanced_buttons1: "undo,redo,|,formatselect,removeformat,|,icrtemail,anchor,link,unlink,|,code",
						theme_advanced_buttons2: "bold,italic,underline,|,sup,sub,|,charmap,|,cut,copy,paste,pastetext,pasteword",
						theme_advanced_buttons3: "",
						schema: "html5",
						body_class: "homepageFeatureBoxCTA"
					}, (iCreateObject.isSiteInICreateMode && !iCreateObject.isSiteInPreviewMode ? { "theme_advanced_styles": "defaultButton-Primary=defaultButton-Primary" } : {})
				)
			}, iCreateObject);
		}


		$('#rotatingBannerContainer .slide .bannerImage').each(function () {
			var link = $(this);
			$.each(this.attributes, function (i, attrib) {
				var name = attrib.name;
				var value = attrib.value;
				link.attr(name, decodeEntities(value))
			});
		});
		var bannerTxt = "";
		if (iCreateObject.lang == "en") {
			bannerTxt = "Banner";
		}
		else {
			bannerTxt = "Bannière";
		}
		$('.ic-slider .slide').each(function (index) {
			$('.ic-slider-pagination, .ic-slider-dot-pagination').append('<li><a href="#" title="' + bannerTxt + ': ' + (index + 1) + '" role="button" aria-label="' + bannerTxt + (index + 1) + '"></a></li>');
			$('.ic-slider-pagination li:first-child, .ic-slider-dot-pagination li:first-child').addClass('current');
		})

		$('<div class="mobileBannerText" role="complementary" aria-label="Mobile Banner Text">').insertAfter('#ic-gal-slider-home');

		/* basic - default settings */
		jQuery('.ic-slider').iosSlider({
			snapToChildren: true,
			scrollbar: false,
			desktopClickDrag: false,
			responsiveSlideContainer: true,
			responsiveSlides: true,
			infiniteSlider: true,
			autoSlide: true,
			autoSlideTimer: 5000,
			autoSlideTransTimer: 0,
			autoSlideHoverPause: true,
			navSlideSelector: jQuery('#ic-gal-slider-home .ic-slider-pagination li, #ic-gal-slider-home .ic-slider-dot-pagination li'),
			navNextSelector: jQuery('#ic-gal-slider-home .next'),
			navPrevSelector: jQuery('#ic-gal-slider-home .prev'),
			onSliderLoaded: sliderShow,
			onSliderResize: sliderResize,
			onSlideChange: fadeSlideChange,
			onSlideComplete: rotateMobileText
		});
		//TEMP FIX; IF <=1 BANNER, PAUSE TO DEAL WITH THE ERROR WITH <=1 BANNER
		if ($('#rotatingBanners .slide') && $('#rotatingBanners .slide').length <= 1) {
			jQuery('.slider-container').find('.ic-slider').iosSlider('autoSlidePause');
		}
		rotateMobileText();
		sliderResize();

		$('.ic-slider-dot-pagination a, .ic-slider-controls a, .next, .prev').click(function (event) {
			event.preventDefault();
			jQuery(this).closest('.slider-container').find('.ic-slider').iosSlider('autoSlidePause');
		});

		// if pause button
		jQuery('.next, .prev, #ic-gal-slider-home .ic-slider-controls .pause').click(function (event) {
			event.preventDefault();
			if (jQuery(this).hasClass('paused')) {
				jQuery(this).closest('.slider-container').find('.ic-slider').iosSlider('autoSlidePlay');
			} else {
				jQuery(this).closest('.slider-container').find('.ic-slider').iosSlider('autoSlidePause');
			}
			jQuery(this).toggleClass('paused');
		});
	}

	if (iCreateObject.isSiteInICreateMode) {
		$(window).load(initSlider);
	} else {
		$(document).ready(initSlider);
	}

	var firstSlide = true;

	function fadeSlideChange(args) {
		$('.ic-fade .bannerImage, .ic-fade .ic-slider-text').css('display', 'none');
		$(args.currentSlideObject).children('.bannerImage, .ic-slider-text').fadeIn('1000');

		//banners with links.  
		$("#rotatingBanners .slide a").attr('tabindex', '-1');
		if ($(args.currentSlideObject).find('a').length) {
			$(args.currentSlideObject).find('> a .bannerImage').fadeIn('1000');
			$(args.currentSlideObject).find('a').removeAttr('tabindex');
		}

		$("#rotatingBanners .slide").removeClass("current");
		$(args.currentSlideObject).addClass("current");

		$('.ic-slider-pagination li, .ic-slider-dot-pagination li').removeClass('current');
		$('.ic-slider-pagination li:eq(' + (args.currentSlideNumber - 1) + '), .ic-slider-dot-pagination li:eq(' + (args.currentSlideNumber - 1) + ')').addClass('current');
		rotateMobileText();
	}

	function rotateMobileText() {
		var textContainer = $('.mobileBannerText');
		var currentSlide = $('#ic-gal-slider-home .ic-slider').data('args').currentSlideNumber;
		textContainer.children('.ic-slider-text:nth-child(' + currentSlide + ')').addClass('visible');

		if (!firstSlide) {
			var previousSlide = $('#ic-gal-slider-home .ic-slider').data('args').prevSlideNumber;
			textContainer.children('.ic-slider-text:nth-child(' + previousSlide + ')').removeClass('visible');
		}
		firstSlide = false;
	}

	function slideChange(args) {
		$("#rotatingBanners .slide").removeClass("current");
		$(args.currentSlideObject).addClass("current");
		$('.ic-slider-pagination li, .ic-slider-dot-pagination li').removeClass('current');
		$('.ic-slider-pagination li:eq(' + (args.currentSlideNumber - 1) + '), .ic-slider-dot-pagination li:eq(' + (args.currentSlideNumber - 1) + ')').addClass('current');
	}

	function sliderShow(args) {
		$('.ic-slider, .ic-slider-controls').addClass('visible');
		$('#ic-gal-slider-home .ic-slider-text').clone().appendTo('.mobileBannerText');
		$(args.currentSlideObject).addClass("current");
		$("#rotatingBanners .slide a").attr('tabindex', '-1');
		if ($(args.currentSlideObject).find('a').length) {
			$(args.currentSlideObject).find('a').removeAttr('tabindex');
		}
		touchPause();
	}

	function sliderOrientationResize() {
		$('#ic-gal-slider-home .ic-slider').iosSlider('update');
	}

	function sliderResize() { }

	function touchPause() {
		// Pause banner if touch device
		if ($('html').hasClass('touch')) {
			jQuery('.slider-container').find('.ic-slider').iosSlider('autoSlidePause');
		} else {
			jQuery('.slider-container').find('.ic-slider').iosSlider('autoSlidePlay');
		}
	}

	//resize the gallery on orientation change if on mobile device, and delay by 1 second to offset loading race condition
	if ("onorientationchange" in window) bindEvent(window, 'orientationchange', function () { setTimeout(function () { sliderOrientationResize(); }, 1000); });
}